<template>
  <div id="AddUsdaIngredient" class="container w-full mx-auto pt-20 pb-20">

    <div class="text-gray-900 text-3xl text-center">100 Most Recent WETH Trading Pairs on Uniswap</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="flex flex-wrap">
      <a :href="reportLink()" target="_blank" class="block w-40 mt-6 px-4 py-3 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-innoorange border border-transparent rounded-lg active:bg-innoorange hover:bg-innoorange-hover focus:outline-none focus:shadow-outline-innoorange">
        Export CSV
      </a>
    </div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/3 px-3">
        <TextInput 
            label="Search"
            placeholder="token address, pool address, or token symbol..."
            v-model:input="searchString"
            inputType="text"
            @keyup.enter="submitStringSearch()"
        />
      </div>
      <div class="w-full md:w-1/3 px-3">
        <TextInput 
            label="Start Date"
            placeholder="36"
            v-model:input="startDate"
            inputType="date"
        />
      </div>
      <div class="w-full md:w-1/3 px-3">
        <TextInput 
            label="End Date"
            placeholder="36"
            v-model:input="endDate"
            inputType="date"
        />
      </div>
    </div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/4 px-3">
        <label class="inline-flex items-center cursor-pointer">
          <input type="checkbox" v-model="ignoreAllToggles" @change="getTradingPairs()" class="sr-only peer">
          <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span class="ml-2 ms-3 text-sm font-medium text-gray-900 dark:text-gray-900">Ignore All Security Toggles</span>
        </label>
      </div>
      <div class="w-full md:w-1/4 px-3">
        <label class="inline-flex items-center cursor-pointer">
          <input type="checkbox" v-model="ignoreOffToggles" @change="getTradingPairs()" class="sr-only peer">
          <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span class="ml-2 ms-3 text-sm font-medium text-gray-900 dark:text-gray-900">Ignore "Off" Security Toggles</span>
        </label>
      </div>
    </div>
    <div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Token Pair
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Discovered
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Buy/Sell Tax
                      </th>
                      <th scope="col" class="px-6 py-3" title="From Dexscreener API">
                          Price
                      </th>
                      <th scope="col" class="px-6 py-3" title="From Dexscreener API">
                          Liquidity
                      </th>
                      <th v-for="dataPoint in securityReportDataPoints" :key="srHeaderKey(dataPoint)" :title="dataPoint.label"
                        scope="col" class="px-6 py-3">
                          <label class="inline-flex items-center cursor-pointer">
                            <input type="checkbox" v-model="dataPoint.toggle_on" @change="srDataPointToggled(dataPoint)" class="sr-only peer">
                            <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          </label>
                          {{ dataPoint.header }}
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(pair, index) in pairs" :key="pair.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <a :href="tokenLink(pair.tokens[0])" target="_blank" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">{{ maxLength(pair.tokens[0].symbol, 10) }}</a> - <a :href="tokenLink(pair.tokens[1])" target="_blank" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">{{ maxLength(pair.tokens[1].symbol, 10) }}</a>
                      </th>
                      <td class="px-6 py-4">
                        {{ localDateTime(pair.created_at) }}
                      </td>
                      <td class="px-6 py-4">
                        {{ buySellTax(pair) }}
                      </td>
                      <td class="px-6 py-4" :title="dexscreenerReportTime(pair)">
                        {{ priceUsd(pair) }}
                      </td>
                      <td class="px-6 py-4" :title="dexscreenerReportTime(pair)">
                        {{ liquidityUsd(pair) }}
                      </td>
                      <td v-for="(dataPoint, dpIndex) in securityReportDataPoints" :key="srValueKey(pair, dataPoint)"
                        :style="{ color: `${srDataColor(dpIndex, pair)}` }"
                        class="px-6 py-4">
                        {{ srData(dataPoint.name, pair)}}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue'
  import TradingPairService from '../../services/tradingPair.service'
  import TextInput from '@/components/global/TextInput.vue'

  let pairs = ref([])
  let errors = ref([])
  let startDate = ref('')
  let endDate = ref('')
  let searchString = ref('')
  let ignoreOffToggles = ref(false)
  let ignoreAllToggles = ref(false)
  let securityReportDataPoints = ref([
    {
      'name': 'anti_whale_modifiable',
      'label': 'Anti-Whale Modifiable',
      'header': 'AMF',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'can_take_back_ownership',
      'label': 'Can Take Back Ownership',
      'header': 'CTBO',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'hidden_owner',
      'label': 'Hidden Owner',
      'header': 'HO',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'honeypot_with_same_creator',
      'label': 'Honeypot With Same Creator',
      'header': 'HWSC',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'is_anti_whale',
      'label': 'Is Anti-Whale',
      'header': 'AW',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'is_blacklisted',
      'label': 'Blacklisted',
      'header': 'BL',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'is_honeypot',
      'label': 'Honeypot',
      'header': 'HP',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'is_open_source',
      'label': 'Open Source',
      'header': 'OS',
      'positive_val': 1,
      'toggle_on': true
    },
    {
      'name': 'is_proxy',
      'label': 'Proxy',
      'header': 'Pxy',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'is_whitelisted',
      'label': 'Whitelisted',
      'header': 'WL',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'personal_slippage_modifiable',
      'label': 'Personal Slippage Modifiable',
      'header': 'PSM',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'selfdestruct',
      'label': 'Self Destruct',
      'header': 'SD',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'slippage_modifiable',
      'label': 'Slippage Modifiable',
      'header': 'SM',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'trading_cooldown',
      'label': 'Trading Cooldown',
      'header': 'TC',
      'positive_val': 0,
      'toggle_on': true
    },
    {
      'name': 'transfer_pausable',
      'label': 'Transfer Pausable',
      'header': 'TP',
      'positive_val': 0,
      'toggle_on': true
    }
  ])

  watch(() => ignoreAllToggles, (newVal) => {
    if (newVal.value) ignoreOffToggles.value = true
  }, { deep: true })

  watch(() => ignoreOffToggles, (newVal) => {
    if (!newVal.value) ignoreAllToggles.value = false
  }, { deep: true })

  onMounted(async () => {
      await getTradingPairs()
  })

  const getTradingPairs = async () => {
    try {
      let params = {}
      params['q'] = searchString.value
      if (!ignoreAllToggles.value) {
        for (let i = 0; i < securityReportDataPoints.value.length; i++) {
          if (securityReportDataPoints.value[i].toggle_on) {
            params[securityReportDataPoints.value[i].name] = securityReportDataPoints.value[i].positive_val
          } else if (!ignoreOffToggles.value) {
            params[securityReportDataPoints.value[i].name] = securityReportDataPoints.value[i].positive_val == 0 ? 1 : 0
          }
        }
      }
      let res = await TradingPairService.listWethPairs(params)
      pairs.value = res.data.trading_pairs
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const submitStringSearch = () => {
    ignoreAllToggles.value = true
    getTradingPairs()
  }

  const tokenLink = (token) => {
    return 'https://etherscan.io/token/' + token.address
  }

  const reportLink = () => {
    let url = process.env.VUE_APP_API_URL + 'token-report?start_date=' + startDate.value
    if (endDate.value != '') url += '&end_date=' + endDate.value
    for (let i = 0; i < securityReportDataPoints.value.length; i++) {
      if (securityReportDataPoints.value[i].toggle_on) {
        url += '&' + securityReportDataPoints.value[i].name + '=' + securityReportDataPoints.value[i].positive_val
      } else {
        url += '&' + securityReportDataPoints.value[i].name + '=' + (securityReportDataPoints.value[i].positive_val == 0 ? 1 : 0)
      }
    }
    return url
  }

  // const usdRound = (num) => {
  //   return Math.round(parseFloat(num) * 100) / 100
  // }

  const localDateTime = (date) => {
    return new Date(date).toLocaleString()
  }

  const srDataColor = (dpIndex, pair) => {
    let dataPoint = securityReportDataPoints.value[dpIndex].name
    let t = pair.tokens[0].address == '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' ? pair.tokens[1] : pair.tokens[0]
    if (t.token_security_reports.length == 0) return 'white'
    let sr = t.token_security_reports[0]
    if (!Object.prototype.hasOwnProperty.call(sr, dataPoint)) return 'white'
    if (sr[dataPoint] == null) return 'white'
    return sr[dataPoint] == securityReportDataPoints.value[dpIndex].positive_val ? 'green' : 'red'
  }

  const srData = (dataPoint, pair) => {
    let t = pair.tokens[0].address == '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' ? pair.tokens[1] : pair.tokens[0]
    if (t.token_security_reports.length == 0) return '-'
    let sr = t.token_security_reports[0]
    if (!Object.prototype.hasOwnProperty.call(sr, dataPoint)) return '-'
    if (sr[dataPoint] == null) return '-'
    return sr[dataPoint] == 1 ? 'true' : 'false'
  }

  const srHeaderKey = (dataPoint) => {
    return dataPoint.name + '_header';
  }

  const srValueKey = (pair, dataPoint) => {
    return dataPoint.name + '_' + pair.id;
  }

  const maxLength = (str, max) => {
    if (str === null) return ''
    if (str.length > max) {
      return str.substr(0, max - 3) + '...'
    }
    return str
  }

  const buySellTax = (pair) => {
    try {
      let t = pair.tokens[0].address == '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' ? pair.tokens[1] : pair.tokens[0]
      if (t.token_security_reports.length == 0) return '-'
      let sr = t.token_security_reports[0]
      let srJson = sr.full_report_json
      let jsonObj = JSON.parse(srJson)
      let buyTaxStr = '?'
      let sellTaxStr = '?'
      let buyTaxVal = jsonObj.result[t.address].buy_tax
      let sellTaxVal = jsonObj.result[t.address].sell_tax
      if (buyTaxVal != undefined && buyTaxVal != '') {
        let buy_tax_rate = parseFloat(buyTaxVal)
        let buy_tax_percent = Math.round(buy_tax_rate * 100 * 100) / 100
        buyTaxStr = buy_tax_percent.toString() + '%'
      }
      if (sellTaxVal != undefined && sellTaxVal != '') {
        let sell_tax_rate = parseFloat(jsonObj.result[t.address].sell_tax)
        let sell_tax_percent = Math.round(sell_tax_rate * 100 * 100) / 100
        sellTaxStr = sell_tax_percent.toString() + '%'
      }
      return buyTaxStr + '/' + sellTaxStr
    } catch (err) {
      return 'error'
    }
  }

  // const getLatestDexscreenerUniswapV2WethPair = (pair) => {
  //   let wethAddress = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
  //   wethAddress = wethAddress.toLowerCase();
  //   let t = pair.tokens[0].address.toLowerCase() == wethAddress ? pair.tokens[1] : pair.tokens[0]
  //   if (t.dexscreener_token_reports.length == 0) return null
  //   let reportPairs = JSON.parse(t.dexscreener_token_reports[0].full_report_json)
  //   for (let i = 0; i < reportPairs.length; i++) {
  //     let dPair = reportPairs[i]
  //     if (dPair.chainId == 'ethereum' && dPair.dexId == 'uniswap' && dPair.labels.includes('v2') && dPair.quoteToken.address.toLowerCase() == wethAddress) {
  //       return dPair
  //     }
  //   }
  //   return null
  // }

  const priceUsd = (pair) => {
    try {
      let t = nonWethToken(pair)
      if (!t.latest_token_price) return '-'
      return '$' + t.latest_token_price.usd_price
    } catch (err) {
      return 'error'
    }
  }

  const liquidityUsd = (pair) => {
    try {
      if (pair.latest_pair_liquidity) {
        // get rounded value
        let liqK = Math.round(pair.latest_pair_liquidity.usd_liquidity / 1000)
        return '$' + liqK.toString() + 'K'
      }
      return '-'
    } catch (err) {
      return 'error'
    }
  }

  const dexscreenerReportTime = (pair) => {
    try {
      let wethAddress = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
      wethAddress = wethAddress.toLowerCase()
      let t = pair.tokens[0].address.toLowerCase() == wethAddress ? pair.tokens[1] : pair.tokens[0]
      if (t.dexscreener_token_reports.length == 0) return 'no timestamp'
      let r = t.dexscreener_token_reports[0]
      return new Date(r.created_at).toLocaleString()
    } catch (err) {
      return 'error'
    }
  }

  const nonWethToken = (pair) => {
    let wethAddress = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
    let t = pair.tokens[0].address.toLowerCase() == wethAddress ? pair.tokens[1] : pair.tokens[0]
    return t
  }

  const srDataPointToggled = (dataPoint) => {
    console.log('toggled', dataPoint)
    getTradingPairs()
  }
</script>
