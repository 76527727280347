import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/store/user-store'
import HomeView from '../views/HomeView.vue'
import RegisterView from '../views/RegisterView.vue'
import LoginView from '../views/LoginView.vue'
import AccountView from '../views/AccountView.vue'

import ListTokensView from '../views/account/ListTokens.vue'
import ListTradingPairsView from '../views/account/ListTradingPairs.vue'

const routes = [
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next('/account/trading-pairs') : next()
    },
    name: 'home',
    component: HomeView
  },
  {
    path: '/register',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next('/account/trading-pairs') : next()
    },
    name: 'register',
    component: RegisterView
  },
  {
    path: '/login',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next('/account/trading-pairs') : next()
    },
    name: 'login',
    component: LoginView
  },
  {
    path: '/account',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next() : next('/login')
    },
    component: AccountView,
    children: [
      {
        path: 'tokens',
        name: 'ListTokens',
        component: ListTokensView
      },
      {
        path: 'trading-pairs',
        name: 'ListTradingPairs',
        component: ListTradingPairsView
      },
      // {
      //   path: 'home',
      //   name: 'AccountHome',
      //   component: AccountHome
      // },
    ]
  },
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
