import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import './styles/app.css'; // Here

import UserService from './services/user.service.js'
import { useUserStore } from './store/user-store.js'

import Toast from "vue-toastification";
import "./styles/vue-toastification.css"

startApp()

async function startApp () {
  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)

  const app = createApp(App)

  const toastOptions = {
    // You can set your default vue-toastification options here
  }

  app.use(router)
  app.use(pinia)
  app.use(Toast, toastOptions)

  app.config.globalProperties.$filters = {
    strLimit(value, size) {
      if (!value) return ''
      value = value.toString()
  
      if (value.length <= size) {
        return value
      }
      return value.substr(0, size).trim() + '...'
    },
    posessiveNoun(value) {
      if (value && value.length > 2) {
        if (value.slice(-1) == 's') return value + "'"
        else return value + "'s"
      }
      return ''
    }
  }

  const userStore = useUserStore()

  // attempt to auto refresh token before startup
  try {
    let res = await UserService.getMe()
    userStore.setUserDetails(res)
  } catch {
    userStore.clearUser()
  }

  app.mount('#app')
}
