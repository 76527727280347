import Api from '../apis/api'

class TradingPairService {
  listWethPairs (params = null) {
    if (params === null) {
      return Api.get(`api/weth-trading-pairs`)
    } else {
      return Api.get(`api/weth-trading-pairs`, {
        params: params
      })
    }
  }
}

export default new TradingPairService()
