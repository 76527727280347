<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="text-gray-900 text-3xl text-center">100 Most Recent Tokens</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="tokens.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Symbol
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Released At
                      </th>
                      <th scope="col" class="px-6 py-3">
                          UniswapV2 Pool Liquidity (USD)
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(token, index) in tokens" :key="token.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <a :href="tokenLink(token)" target="_blank" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">{{ token.symbol }}</a>
                      </th>
                      <td class="px-6 py-4">
                        {{ token.token_created_at }}
                      </td>
                      <td class="px-6 py-4">
                        {{ tokenLiquidity(token) }}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import TokenService from '../../services/token.service'

  let tokens = ref([])
  let errors = ref([])

  onMounted(async () => {
      await getTokens()
  })

  const getTokens = async () => {
    try {
      let res = await TokenService.listTokens()
      tokens.value = res.data.tokens
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const tokenLink = (token) => {
    return 'https://etherscan.io/token/' + token.address
  }

  const tokenLiquidity = (token) => {
    if (token.token_security_reports.length <= 0) {
      return 'unknown'
    }
    let report = JSON.parse(token.token_security_reports[0].full_report_json)
    if (!report.result || !report.result[token.address] || !report.result[token.address].dex) return 'unknown'
    for (let i = 0; i < report.result[token.address].dex.length; i++) {
      let dex = report.result[token.address].dex[i]
      if (dex.liquidity_type == 'UniV2') return valueToUsd(dex.liquidity)
    }
    return 'unknown'
  }

  const valueToUsd = (value) => {
    let formatting_options = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }
    let dollarString = new Intl.NumberFormat("en-US", formatting_options)
    return dollarString.format(value)
  }
</script>
