<template>
  <div id="Register">
    <TopNavigation />

    <div class="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl mt-20 mb-20">
      <div class="flex flex-col md:flex-row">
        <div class="h-32 md:h-auto md:w-1/2">
          <img class="object-cover w-full h-full" src="@/assets/dog_carrot.jpg"
            alt="ChefPaw with Carrot" />
        </div>
        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
          <div class="w-full">
            <h1 class="mb-4 text-2xl font-bold text-center text-gray-700">
              Register
            </h1>
            <div class="mt-4">
              <TextInput 
                  label="First Name"
                  :labelColor="black"
                  placeholder="Sam"
                  v-model:input="firstName"
                  inputType="text"
                  :error="errors.first_name ? errors.first_name[0] : ''"
              />
            </div>
            <div class="pt-4">
              <TextInput 
                  label="Last Name"
                  :labelColor="black"
                  placeholder="Doe"
                  v-model:input="lastName"
                  inputType="text"
                  :error="errors.last_name ? errors.last_name[0] : ''"
              />
            </div>
            <div class="pt-4">
              <TextInput 
                  label="Email"
                  :labelColor="black"
                  placeholder="sam.doe@m.com"
                  v-model:input="email"
                  inputType="text"
                  :error="errors.email ? errors.email[0] : ''"
              />
            </div>
            <div class="pt-4">
              <TextInput 
                  label="Password"
                  :labelColor="black"
                  placeholder="password123?"
                  v-model:input="password"
                  inputType="password"
                  :error="errors.password ? errors.password[0] : ''"
              />
            </div>
            <div class="pt-4">
              <TextInput 
                  label="Confirm Password"
                  :labelColor="black"
                  placeholder="password123?"
                  v-model:input="confirmPassword"
                  inputType="password"
                  :error="errors.password_confirmation ? errors.password_confirmation[0] : ''"
              />
            </div>
            <button class="block w-full mt-6 px-4 py-3 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-innoorange border border-transparent rounded-lg active:bg-innoorange hover:bg-innoorange-hover focus:outline-none focus:shadow-outline-innoorange"
              type="submit"
              @click="register">
              Register
            </button>

            <div class="mt-4 text-center">
              <p class="text-sm">Already have an account?
                <router-link to="login" class="text-blue-600 no-underline hover:underline">
                  Login
                </router-link>
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterSection />
  </div>
</template>

<script setup>
  import axios from 'axios';
  import { useRouter } from 'vue-router'
  import { ref } from 'vue';
  import { useUserStore } from '../store/user-store'
  import TextInput from '../components/global/TextInput.vue'
  import TopNavigation from '../components/structure/TopNavigation.vue'
  import FooterSection from '../components/structure/FooterSection.vue'

  const router = useRouter()
  const userStore = useUserStore()

  let errors = ref([])
  let firstName = ref(null)
  let lastName = ref(null)
  let email = ref(null)
  let password = ref(null)
  let confirmPassword = ref(null)

  const register = async () => {
    errors.value = []

    try {
      let res = await axios.post('register', {
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
        password: password.value,
        password_confirmation: confirmPassword.value
      })

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
      userStore.setUserDetails(res)
      router.push('/account/profile')
    } catch(err) {
      errors.value = err.response.data.errors
    }
  }
</script>