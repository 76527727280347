// stores/user-store.js
import axios from 'axios'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    id: null,
    token: null,
    firstName: null,
    lastName: null,
    email: null,
    image: null,
    role_names: []
  }),
  getters: {
    getRoleNames: (state) => state.role_names,
    isAdmin: (state) => state.role_names.includes('admin')
  },
  actions: {
    setUserDetails(res) {
      if (res.data.token) this.$state.token = res.data.token
      this.$state.id = res.data.user.id
      this.$state.firstName = res.data.user.first_name
      this.$state.lastName = res.data.user.last_name
      this.$state.email = res.data.user.email
      this.$state.role_names = res.data.user.role_names
    },

    async fetchUser() {
      let res = await axios.get('users/me')

      this.$state.id = res.data.user.id
      this.$state.firstName = res.data.user.first_name
      this.$state.lastName = res.data.user.last_name
      this.$state.role_names = res.data.user.role_names
      if (res.data.user.image) {
        this.$state.image = process.env.VUE_APP_API_URL + 'images/users/' + res.data.user.image
      } else {
        this.$state.image = process.env.VUE_APP_URL + 'DefaultUserAvatar.png'
      }
    },

    async clearUser() {
      this.$state.id = null
      this.$state.token = null
      this.$state.firstName = null
      this.$state.lastName = null
      this.$state.email = null
      this.$state.role_names = []
    }
  },
  persist: true
})
